import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import CtrlNavbar from '../components/navbar';
import SEO from '../components/seo';

const NotFoundPage = (): JSX.Element => {
  const intl = useIntl()

  return (
    <>
      <CtrlNavbar />
      <SEO title="404: Not found" />
      <div className="align-items-center d-flex flex-column justify-content-center position-absolute pt-5 w-100">
        <h1>
          {intl.formatMessage({ id: 'general.notFound' })}
        </h1>
        <Link to="/" className="subfooter-link">

          {intl.formatMessage({ id: 'general.backToHome' })}
        </Link>
      </div>
    </>
  )
}

export default NotFoundPage
